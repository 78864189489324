<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button
              label="Back"
              @click="backToList()"
              id="back"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property</label>
          <div class="p-col">
            <v-select :disabled="mode=='edit'" :clearable="false" title="Property" class="" @search="SearchProperty"
              placeholder="Choose" v-model="obj_data.property_id" :options="listProperty"
              :reduce="property_item => property_item.id" label="name">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>

        <!-- <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Title</label>
          <div class="p-col">
            <InputText maxlength="100" id="name" type="text" class="" v-model="obj_data.title" placeholder="Input" />
          </div>
        </div> -->
      </div>


      <div v-for="(item, index) in listLang" :key="item.id">

        <!-- Field for Service Name and Description -->
        <div class="col-6" v-if="item.iso_code === 'dk'">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Title ({{ item.iso_code.toUpperCase() }})</label>
            <div class="p-col">
              <InputText maxlength="100" :id="'name_' + item.iso_code" type="text" v-model="item.service_name"
                placeholder="Input" />
            </div>
          </div>
        </div>


        <!-- Checkbox to enable English fields -->
        <div class="col-6" v-if="item.iso_code === 'dk' && index === 0">
          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.is_use_en" :binary="true" />
              <label class="p-col-fixed txt-right text">Use English</label>
            </div>
          </div>
        </div>

        <!-- English Section -->  
        <div class="col-6" v-if="item.iso_code === 'en'">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Title ({{ item.iso_code.toUpperCase() }})</label>
            <div class="p-col">
              <InputText :disabled="!obj_data.is_use_en" maxlength="100" :id="'name_' + item.iso_code" type="text"
                v-model="item.service_name" placeholder="Input" />
            </div>
          </div>
        </div>
      
      
      </div>




      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Document category</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchDocsCate" placeholder="Choose"
              v-model="obj_data.doc_category_id" :options="listDocsCate" :reduce="doc_cate_item => doc_cate_item.id"
              label="name">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="doc_category_id">
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Attached file</label>
          <div class="p-col">
            <div class="cus-text">Maximum:100 MB</div>
            <div class="cus-text">Accepted file:pdf</div>
            <input class="p-inputtext p-component" accept="application/pdf" name="images[]" id="images_service"
              type="file" @change="onFileSelectPdf" /><!--@change="onFileSelectLogo"-->
            <input class="input_tmp_validator" id="file_pdf">
            <div class="cus-text">
              <span :key="comFilename">{{ obj_data.filename }}</span>
              <!--              <div class="button-download" ><img src="/assets/images/icon/cancel_black_24dp.png"/></div>
              <div class="button-clear" ><img src="/assets/images/icon/cancel_black_24dp.png"/></div>-->
            </div>

            <!--            <div class="select-button" style="background-color: rgb(227, 237, 252);height: max-content;text-align: justify;justify-items: center;display: flex;padding: 0.55rem;margin: -0.429rem;">
              <span style="margin: auto;">Tải lên</span>
            </div>-->
          </div>
        </div>
        <!--        <div class="p-field p-col-12">
          <label class="p-col-fixed txt-left">Icon danh mục <span class="span-cus">*</span></label>
          <div class="p-col">
            <div class="p-col-width p-inputtext p-component" style="width: 100% !important; display: flex; justify-content: space-between; padding: 0.429rem">
              <span id="file_name"></span>
              <label class="file-select" style="height: 100%;padding: 0;">
                <input class="p-inputtext p-component" accept="image/*" name="images[]" id="images_service" type="file" @change="onFileSelectLogo"/>
                <div class="select-button" style="background-color: rgb(227, 237, 252);height: max-content;text-align: justify;justify-items: center;display: flex;padding: 0.55rem;margin: -0.429rem;">
                  <span style="margin: auto;">Tải lên</span>
                </div>
              </label>
            </div>
            <input class="input_tmp_validator" id="icon_service_va">
            <div class="cus-text">- Chấp nhận: JPG, JPEG, PNG, SVG.</div>
            <div class="cus-text">- Khuyên dùng 390x794px.</div>
            <div class="cus-text">- Dung lượng tối đa: 2 MB.</div>
            <div class="box-images p-grid"  v-if="icon_service && icon_service.length">
              <div class="item-img p-col-5" v-for="(item, index) in icon_service" :key="index" >
                <div class="button-clear" @click="onFileClear(index,'icon_service')"><img :src="require('../../../assets/ic_clear.svg')"/></div>
                <img :src="item.objectURL"/>
              </div>
            </div>
          </div>
        </div>-->


        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Order</label>
          <div class="p-col">
            <InputNumber id="ordinal" v-model="obj_data.ordinal">
            </InputNumber>
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.show_only_owner" :binary="true" />
            <label class="p-col-fixed txt-right text">For property owner</label>
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.show_member" :binary="true" />
            <label class="p-col-fixed txt-right text">For 521's member</label>
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.show_rent" :binary="true" />
            <label class="p-col-fixed txt-right text">For external renter</label>
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.active" :binary="true" />
            <label class="p-col-fixed txt-right text">Active</label>
          </div>
        </div>

        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">From - to</label>
          <div class="p-col">
            <Calendar 
              :showOnFocus="false" 
              dateFormat="MM dd, yy" 
              class=""
              :showIcon="true" 
              selectionMode="range"
              appendTo="body"
              :showClear="true" 
              :manualInput="false"
              v-model="from_to" :disabled="mode=='edit'" placeholder="Choose"
            />
            <input class="input_tmp_validator" id="from_to"/>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Start booking</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  v-model="start_booking_date" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="start_booking_date"/>
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">End</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="end_booking_date"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="end_booking_date"
                  placeholder="Choose"
                />
              </div>
            </div>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <h3>ORDER OF BOOKING ({{partArr.length}}/5)</h3>
        </div>
        <packageDescriptions :key="componentKey" v-model="partArr" :mode="mode"/>
        
        <matrixComponent @updateMatrix="updateMatrix" v-model="partArr" :dataBook="dataBookingRound" :show="true" :property="selProperty" :key="`matrix-${componentKey}`"/>
        
        <div v-if="mode == 'edit'" class="p-card-field">
          <a @click="toCalendar" class="txt-link pl-3"><i>View on calendar</i></a>
        </div>-->
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
            class="main-style-button" />
          <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
//import DataServices from '@/core/DataServices';
import moment from "moment-timezone";

export default {
  components: {

  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      obj_data:{
        filename:""
      },
      comFilename:1,
      dataBookingRound: [],
      selProperty: null,
      listProperty: [],
      listPropertyAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local : moment.tz.guess(true),
      componentKey: 0,
      file_pdf :[],
      maxFileSize: 2097152*50 , //100MB  2MB   /*104857600, // 100MB*/
      listLang : [],
    }
  },
  watch: {
   /* async "selProperty"() {
      if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
        this.address = this.selProperty?.address;
        this.partArr = this.selProperty?.part;
        if (this.mode == 'add') {
          var arr = [];
          this.partArr.map(m => {
            arr.push({ord: m.order_booking, id: m.id});
          })
          arr.map(m=> {
            var tmp = [];
            for (let i =0; i < arr.length; i++) {
              let a = m.ord - i + arr.length;
              if (a > arr.length) {
                a = a - arr.length;
              }
              tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
            }
            this.dataBookingRound.push(tmp);
          })
        }
        this.componentKey++;
      }
    },
    "start_booking_date"() {
      if (this.partArr.length) {
        let days = 14 * this.partArr.length;
        this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
      }
    }*/
  },
  async mounted() {
    var dataLang = await this.$CoreService.getListLang({});
    var list_lang = dataLang.list;

    await this.getListProperty();
    await this.getListDocsCate();
    
    console.log("this.model:",this.model)
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.obj_data = this.model;
      this.obj_data.filename = this.model?.item_pdf.file_name ?? ''









      var lang_ser_arr = this.model?.lang_arr ?? [];
      var mapLS = {};
      for (let h = 0; h < lang_ser_arr.length; h++) {
        var itemLS = lang_ser_arr[h];
        mapLS[itemLS.id_lang] = itemLS;
      }

      for (let i = 0; i < list_lang.length; i++) {
        var itemL = list_lang[i];
        var id_l = itemL.id
        list_lang[i].content = (!this.$commonFuction.isEmpty(mapLS[id_l])) ? mapLS[id_l].description : '';
        list_lang[i].service_name = (!this.$commonFuction.isEmpty(mapLS[id_l])) ? mapLS[id_l].name : '';
      }
      this.listLang = list_lang
      console.log("GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG", this.listLang);






      this.componentKey++;
    }else {
      for (let i = 0; i < list_lang.length; i++) {
        list_lang[i].content = '';
        list_lang[i].service_name = '';
      }
      this.listLang = list_lang
      //thêm mới
      this.obj_data = {
        active: true,
        ordinal: 1,
      };
      this.componentKey++;
    }
  },
  methods: {
    handleDataCkditor: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'content') {
        //this.content = dataContent;
        this.listLang[index].content = dataContent;
      }
    }
    ,
    async onFileSelectPdf(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:",files)
      console.log("files.length:",files.length)
      if(files.length > 0) {
        let acceptTags = ['pdf']
        console.log("files.length1111111:",files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:",files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: pdf");
            return;
          }
        //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:",files[i].name)
          that.obj_data.filename =files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:',file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:",file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:",arrTemp)
          this.file_pdf = [];
          this.file_pdf = this.file_pdf.concat.apply(this.file_pdf, arrTemp);
        }
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListProperty(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListProperty(where_key);
      this.listProperty =list.list;
      this.listPropertyAll = list.list;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.text_group_property = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListProperty(where_key);
        this.listProperty = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },

    async getListDocsCate(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListDocsCate(where_key);
      this.listDocsCate =list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListDocsCate(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};


      // let name_add = document.getElementById("name");
      // if (this.$commonFuction.isEmptyObject(this.obj_data.title)){
      //   name_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      // }else {
      //   name_add.setCustomValidity("");
      // }

      let property_add = document.getElementById("property");
      if (this.$commonFuction.isEmptyObject(this.obj_data.property_id)){
        property_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      }else {
        property_add.setCustomValidity("");
      }

      let doc_category_id_add = document.getElementById("doc_category_id");
      if (this.$commonFuction.isEmptyObject(this.obj_data.doc_category_id)){
        doc_category_id_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      }else {
        doc_category_id_add.setCustomValidity("");
      }
      let file_pdf_add = document.getElementById("file_pdf");
      if (this.$commonFuction.isEmptyObject(this.obj_data.filename)){
        file_pdf_add.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      }else {
        file_pdf_add.setCustomValidity("");
      }

      let ordinal = document.getElementById("ordinal");
      if (!this.$commonFuction.isEmptyObject(this.obj_data.ordinal)) {
        if (parseFloat(this.obj_data.ordinal) < 0){
          ordinal.setCustomValidity(this.$constants.MSG_TEXT.INT_MIN_VALIDATE);
        }else {
          ordinal.setCustomValidity("");
        }
      } else {
        ordinal.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      //console.log("this.from_to", this.from_to);
      //console.log("partArr", this.dataBookingRound);
      var params = this.obj_data
      params["file_pdf"] = this.file_pdf
      params["list_lang"] = this.listLang
      console.log('saveDatasaveDatasaveDatasaveDatasaveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
.cus-text{
  margin-left: 2px;
  margin-top: 4px;
  position: relative;
  .button-clear{
    position: absolute;
    left: 0px;
  }
}
</style>
